import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Text from '@tds/core-text';
import Heading from '@tds/core-heading';

class Greeting extends Component {
  setTitle = lang =>
    'en' === lang
      ? 'Digital literacy workshops'
      : 'Ateliers de littératie numérique'
  setHeading = lang =>
    'en' === lang
      ? 'Empowering Canadians to stay safe in our digital world.'
      : 'Assurer la sécurité des Canadiens dans le monde numérique';
  setFirstTextP1 = lang =>
    'en' === lang
      ? 'TELUS Wise'
      : 'Les ateliers de TELUS Averti';
  setFirstTextP2 = lang =>
    'en' === lang
      ? '® '
      : 'MD ';
  setFirstTextP3 = lang =>
    'en' === lang
      ? 'workshops help Canadians of all ages have a positive experience online and cover topics including, protecting your security and privacy, rising above cyberbullying, and using technology responsibly.'
      : 'aident les Canadiens de tous âges à vivre une expérience positive en ligne, notamment en abordant des sujets comme la protection de leur sécurité et de leur vie privée, la lutte contre la cyberintimidation et l’utilisation responsable de la technologie.';
  setSecondText = lang =>
    'en' === lang
      ? 'These online workshops can be completed individually, or a teacher or workshop facilitator can deliver them to a group. To access workshops submit the form below.'
      : 'Ces ateliers en ligne sont suivis individuellement ou présentés en groupe par un enseignant ou un animateur. Pour accéder aux ateliers, veuillez soumettre le formulaire ci-dessous.';

  render() {
    return (
      <div className={`greeting ${this.props.lang}`}>
        <div className={'greetingText'}>
          <Heading level="h1">{this.setTitle(this.props.lang)}</Heading>
          <Text size={"large"} id="greetingHeader">
            {this.setHeading(this.props.lang)}
          </Text>
          <br />
          <br />
          <Text size="medium">
            {this.setFirstTextP1(this.props.lang)}
            <Text.Sup>{this.setFirstTextP2(this.props.lang)}</Text.Sup>
            {this.setFirstTextP3(this.props.lang)}
          </Text>
          <br />
          <br />
          <Text size="medium">{this.setSecondText(this.props.lang)}</Text>
        </div>
      </div>
    );
  }
}

Greeting.propTypes = {
  lang: PropTypes.string.isRequired
};

export default Greeting;

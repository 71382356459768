import React, { Component } from 'react';
import '../language-select/LanguageSelect';
import LanguageSelect from '../language-select/LanguageSelect';
import PropTypes from 'prop-types';

class Header extends Component {
  render() {
    let props = {
      lang: this.props.lang,
      setLang: this.props.setLang
    };
    return (
      <div className={`header ${this.props.lang}`}>
        <div className="headerIcon" />
        <div className='headerLogo'/>
        <div className="formInput languageSelect">
          <LanguageSelect {...props} />
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  lang: PropTypes.string.isRequired,
  setLang: PropTypes.func.isRequired
};

export default Header;

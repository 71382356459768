import React, { Component } from 'react';
import Header from './header/Header';
import Greeting from './greeting/Greeting';
import View from './view/View';
import ReactGA from 'react-ga';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { language: 'en' };
  }

  componentDidMount() {
    ReactGA.initialize('UA-152962365-7');
    let query = window.location.search.substring(1).toLowerCase();
    let pos = query.search('language=french');
    if (-1 !== pos) {
      this.setState({
        language: 'fr'
      });
    }
  };

  render() {
    let props = {
      lang: this.state.language,
      setLang: event => {
        this.setState({ language: event.target.value });
      }
    };
    return (
      <div className="main">
        <Header {...props} />
        <Greeting lang={this.state.language} />
        <View {...props} />
      </div>
    );
  }
}

export default App;

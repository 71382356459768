import React, {Component} from 'react';
import '../login-forms/LoginForms';
import PropTypes from "prop-types";
import Input from '@tds/core-input';
import Button from '@tds/core-button';
import Notification from '@tds/core-notification';

class AdminLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      notificationStatus: undefined,
      notification: undefined,
      passwordStatus: undefined,
      passwordError: undefined,
      usernameStatus: undefined,
      usernameError: undefined,
      errorStatus: undefined
    };
  }

  setUsernameLang = (lang) => 'en' === lang ? 'Admin Username' : 'Nom d\'utilisateur Admin';
  setPasswordLang = (lang) => 'en' === lang ? 'Password' : 'Mot de passe';
  setSubmitLang = (lang) => 'en' === lang ? 'Login' : 'S\'identifier';
  setReturnLinkLang = (lang) => 'en' === lang ? 'Back' : 'Retourner';

  onChangeHandler = event => this.setState({[event.target.name]: event.target.value});

  validateNonEmptyInput = event => {
    if (/(.|\s)*\S(.|\s)*/.test(event.target.value)) {
      this.setState({
        [event.target.name + 'Status']: undefined,
      });
    } else {
      this.setState({[event.target.name + 'Status']: 'error'});
    }
  };

  setUsernameError = () => {
    if ('error' === this.state.usernameStatus) {
      if ('en' === this.props.lang)
        return 'Please enter a username.';
      else
        return 'Merci d\'entrer un nom d\'utilisateur.';
    } else
      return undefined;
  };
  setPasswordError = () => {
    if ('error' === this.state.passwordStatus) {
      if ('en' === this.props.lang)
        return 'Please enter a password.';
      else
        return 'Veuillez entrer un mot de passe.';
    } else
      return undefined;
  };
  validateForm = event => {
    event.preventDefault();
    let formValid = true;
    if (!/(.|\s)*\S(.|\s)*/.test(this.state.password)) {
      this.setState({passwordStatus: 'error'});
      formValid = false;
    } else {
      this.setState({passwordStatus: undefined});
    }
    if (!/(.|\s)*\S(.|\s)*/.test(this.state.username)) {
      this.setState({usernameStatus: 'error'});
      formValid = false;
    } else {
      this.setState({usernameStatus: undefined});
    }
    if (formValid)
      this.handleSubmit(event);
  };

  handleSubmit(event) {
    event.preventDefault();
    const data = new FormData();
    data.append('username', this.state.username);
    data.append('password', this.state.password);
    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function () {
      if (200 === xhr.status) {
        this.setState({errorStatus: 200, notificationStatus: undefined, notification: ''});
        window.location = xhr.responseText.replace(/['"]+/g, '');
      } else {
        this.setState({
          errorStatus: xhr.status,
          notificationStatus: 'error',
          notification: xhr.responseText.replace(/['"]+/g, '')
        });
      }
    }.bind(this);
    xhr.open("POST", "/includes/modules/FM_WiseWorkshops/adminLogin.php", true);
    xhr.send(data);
  };

  notification = () => {
    if ('error' === this.state.notificationStatus) {
      if (200 === this.state.errorStatus) {
        this.setState({notificationStatus: undefined});
      } else if (400 === this.state.errorStatus) {
        if ('en' === this.props.lang) {
          return <Notification role="alert" variant="error">There was an error processing data you entered.</Notification>
        } else {
          return <Notification role="alert" variant="error">Une erreur s'est produite lors du traitement des données que vous avez
            entrées.</Notification>
        }
      } else if (401 === this.state.errorStatus) {
        if ('' === this.state.notification) {
          if ('en' === this.props.lang) {
            return <Notification role="alert" variant="error">Login credentials do not match our records.</Notification>
          } else {
            return <Notification role="alert" variant="error">Les informations d'identification de connexion ne correspondent pas à
              nos enregistrements.</Notification>
          }
        } else {
          return <Notification role="alert" variant="error">{this.state.notification}</Notification>
        }
      } else if (400 <= this.state.errorStatus && 500 > this.state.errorStatus) {
        if ('en' === this.props.lang) {
          return <Notification role="alert" variant="error">An unknown error has occurred.</Notification>
        } else {
          return <Notification role="alert" variant="error">Une erreur inconnue s'est produite.</Notification>
        }
      } else if (500 <= this.state.errorStatus) {
        if ('en' === this.props.lang) {
          return <Notification role="alert" variant="error">An internal server error has occurred.</Notification>
        } else {
          return <Notification role="alert" variant="error">Une erreur de serveur interne s'est produite.</Notification>
        }
      }
    }
  };

  render() {
    return (
      <form onSubmit={this.validateForm}>
        <Input name="username" label={this.setUsernameLang(this.props.lang)} value={this.state.username}
               autoComplete="off" autoCapitalize="none" autoCorrect="off" autoSave="none" spellCheck="false"
               id="input#username" feedback={this.state.usernameStatus} error={this.setUsernameError()}
               onChange={this.onChangeHandler} onBlur={this.validateNonEmptyInput}/>
        <Input name="password" label={this.setPasswordLang(this.props.lang)} value={this.state.password}
               autoComplete="off" autoCapitalize="none" autoCorrect="off" autoSave="none" spellCheck="false"
               id="input#password" type="password" feedback={this.state.passwordStatus} error={this.setPasswordError()}
               onChange={this.onChangeHandler} onBlur={this.validateNonEmptyInput}/>
        {this.notification()}
        <Button type="submit">{this.setSubmitLang(this.props.lang)}</Button>
        <Button name="workInfo" variant="secondary"
                onClick={this.props.setFormName}>{this.setReturnLinkLang(this.props.lang)}</Button>
      </form>
    );
  }
}

AdminLogin.propTypes = {
  lang: PropTypes.string.isRequired,
  setFormName: PropTypes.func.isRequired
};

export default AdminLogin;

import React, { Component } from 'react';
import Radio from '@tds/core-radio';
import Box from '@tds/core-box';
import Text from '@tds/core-text';
import PropTypes from 'prop-types';

class LanguageSelect extends Component {
  setLabelEn = lang => ('en' === lang ? 'English' : 'Anglais');
  setLabelFr = lang => ('en' === lang ? 'French' : 'Français');
  setText = lang => ('en' === lang ? 'Language' : 'Langue');

  render() {
    return (
      <div role="radiogroup">
        <Box tag="fieldset">
          <legend>
            <Text bold size="medium">
              {this.setText(this.props.lang)}
            </Text>
          </legend>
          <Radio
            label={this.setLabelEn(this.props.lang)}
            name="langButton"
            value="en"
            checked={this.props.lang === 'en'}
            onChange={this.props.setLang}
          />
          <Radio
            label={this.setLabelFr(this.props.lang)}
            name="langButton"
            value="fr"
            checked={this.props.lang === 'fr'}
            onChange={this.props.setLang}
          />
        </Box>
      </div>
    );
  }
}

LanguageSelect.propTypes = {
  lang: PropTypes.string.isRequired,
  setLang: PropTypes.func.isRequired
};

export default LanguageSelect;

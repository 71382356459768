import React, { Component } from 'react';
import '../login-forms/LoginForms';
import PropTypes from 'prop-types';
import LoginForms from '../login-forms/LoginForms';

class View extends Component {
  constructor(props) {
    super(props);
    this.state = { form: 'login' };
  }

  render() {
    let props = {
      lang: this.props.lang,
      setLang: this.props.setLang,
      form: this.state.form,
      setFormName: event => {
        this.setState({ form: event.target.name });
      }
    };
    return (
      <div className={`view ${this.state.form}`}>
        <LoginForms {...props} />
      </div>
    );
  }
}

View.propTypes = {
  lang: PropTypes.string.isRequired,
  setLang: PropTypes.func.isRequired
};

export default View;

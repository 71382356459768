import React, {Component} from 'react';
import '../login-forms/LoginForms';
import PropTypes from 'prop-types';
import Input from '@tds/core-input';
import Button from '@tds/core-button';
import Radio from '@tds/core-radio';
import Box from '@tds/core-box';
import Text from '@tds/core-text';
import Link from '@tds/core-link';
import Paragraph from '@tds/core-paragraph';
import Notification from '@tds/core-notification';
import Checkbox from '@tds/core-checkbox';
import ReactGA from 'react-ga';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationStatus: undefined,
      notification: undefined,
      subscribe: false,
      emailStatus: undefined,
      emailError: undefined,
      workshopStatus: undefined,
      workshopError: undefined,
      numStudentsStatus: undefined,
      numStudentsError: undefined
    };
  }

  setCheckboxLang = lang => ('en' === lang ? 'Subscribe to the TELUS Wise newsletter' : 'S’abonner à notre bulletin TELUS Averti');
  setEmailLang = lang => ('en' === lang ? 'E-mail address' : 'Adresse courriel');
  setSubmitLang = lang => ('en' === lang ? 'Continue' : 'Continuer');
  setStudentNumber = lang =>
    'en' === lang ? 'Number of workshop participants' : "Nombre de participants";
  setRadioLabel = lang => ('en' === lang ? 'Workshop' : 'Atelier');
  setFootprintLang = lang =>
    'en' === lang
      ? 'TELUS Wise footprint | Grades 2 - 5'
      : 'TELUS CyberFuté | Niveaux primaires';
  setImpactLang = lang =>
    'en' === lang
      ? 'TELUS Wise impact | Grades 6 - 9'
      : 'TELUS Averti Agis! | Niveaux secondaires';
  setInControlLang = lang =>
    'en' === lang
      ? 'TELUS Wise in control | Grades 6 - 9'
      : 'TELUS Averti Plein contrôle | Niveaux secondaires';
  setHappinessLang = lang =>
    'en' === lang
      ? 'TELUS Wise happiness | Grades 9 - 12'
      : 'TELUS Averti Bien-être | Niveaux secondaires';
  setSupportingLang = lang =>
    'en' === lang
      ? 'TELUS Wise parents | Supporting youth in our digital world'
      : 'TELUS Averti parents  | Soutien des jeunes dans un monde numérique';
  setEmpoweringLang = lang =>
    'en' === lang
      ? 'TELUS Wise adults and seniors | Empowering you to stay safe in our digital world'
      : 'TELUS Averti adultes et personnes âgées | Votre propre sécurité dans notre monde numérique';
  setAILang = lang =>
    'en' === lang
      ? 'TELUS Wise responsible AI | Grades 9 - 12'
      : 'TELUS Averti : Utilisation responsable de l’intelligence artificielle (IA) | Niveau secondaires';
  setAdminLoginLinkLang = lang =>
    'en' === lang ? 'Administrator Login' : 'Login Administrateur';

  handleCheckBox = event => {
    this.setState({subscribe: event.target.checked});
  };

  validateEmail = event => {
    if (
      /^[_A-Za-z0-9-+]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$/.test(
        event.target.value
      )
    ) {
      this.setState({
        emailStatus: undefined
      });
    } else {
      this.setState({emailStatus: 'error'});
    }
  };
  validateNumber = event => {
    if (/^[0-9]+$/.test(event.target.value)) {
      this.setState({numStudentsStatus: undefined});
    } else {
      this.setState({numStudentsStatus: 'error'});
    }
  };
  validateRadio = event => {
    this.props.setInput(event);
    if ('' === event.target.name) {
      this.setState({workshopStatus: 'error'});
    } else {
      this.setState({workshopStatus: undefined});
    }
  };
  setEmailError = () => {
    if ('error' === this.state.emailStatus) {
      if ('en' === this.props.lang)
        return 'That is not a valid E-mail Address.';
      else return "Ce n'est pas une adresse courriel valide.";
    } else return undefined;
  };
  setNumberError = () => {
    if ('error' === this.state.numStudentsStatus) {
      if ('en' === this.props.lang) return 'Please enter a numerical value.';
      else return "S'il vous plaît entrer une valeur numérique.";
    } else return undefined;
  };
  setRadioError = () => {
    if ('error' === this.state.workshopStatus) {
      if ('en' === this.props.lang) return 'Please select an option.';
      else return 'Veuillez sélectionner une option.\n';
    } else return undefined;
  };

  validateForm = event => {
    event.preventDefault();
    let formValid = true;
    if ('' === this.props.workshop) {
      formValid = false;
      this.setState({workshopStatus: 'error'});
    } else {
      this.setState({workshopStatus: undefined});
    }
    if (!/^[0-9]+$/.test(this.props.numStudents)) {
      formValid = false;
      this.setState({numStudentsStatus: 'error'});
    } else {
      this.setState({numStudentsStatus: undefined});
    }
    if (
      !/^[_A-Za-z0-9-+]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$/.test(
        this.props.email
      )
    ) {
      this.setState({emailStatus: 'error'});
      formValid = false;
    } else {
      this.setState({emailStatus: undefined});
    }
    if (formValid) {
      document.getElementById("submitButton").setAttribute("disabled", "disabled");
      this.handleSubmit(event);
    }
  };

  handleSubmit(event) {
    event.preventDefault();

    ReactGA.event({
      category: 'Login',
      action: 'registered',
      label: 'Participants',
      value: parseInt(this.props.numStudents)
    })

    const data = new FormData();
    data.append('email', this.props.email);
    data.append('lang', this.props.lang);
    data.append('students', this.props.numStudents);
    data.append('workshop', this.props.workshop);
    data.append('subscribe', this.state.subscribe);
    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function () {
      if (200 === xhr.status) {
        this.setState({
          errorStatus: 200,
          notificationStatus: undefined,
          notification: ''
        });
        window.location = '/includes/modules/FM_LoginApis/post_login.php';
      } else {
        this.setState({
          errorStatus: xhr.status,
          notificationStatus: 'error',
          notification: xhr.responseText.replace(/['"]+/g, '')
        });
        document.getElementById("submitButton").setAttribute("disabled", "");
      }
    }.bind(this);
    xhr.open('POST', '/includes/modules/FM_WiseWorkshops/createUserAndLogin.php', true);
    xhr.send(data);
  }

  notification = () => {
    if ('error' === this.state.notificationStatus) {
      if (200 === this.state.errorStatus) {
        this.setState({notificationStatus: undefined});
      } else if (400 === this.state.errorStatus) {
        if ('' === this.state.notification) {
          if ('en' === this.props.lang) {
            return (
              <Notification variant="error">
                There was an error processing data you entered.
              </Notification>
            );
          } else {
            return (
              <Notification variant="error">
                Une erreur s'est produite lors du traitement des données que
                vous avez entrées.
              </Notification>
            );
          }
        } else {
          return (
            <Notification variant="error">
              {this.state.notification}
            </Notification>
          );
        }
      } else if (401 === this.state.errorStatus) {
        if ('' === this.state.notification) {
          if ('en' === this.props.lang) {
            return (
              <Notification variant="error">
                Login credentials do not match our records.
              </Notification>
            );
          } else {
            return (
              <Notification variant="error">
                Les informations d'identification de connexion ne correspondent
                pas à nos enregistrements.
              </Notification>
            );
          }
        } else {
          return (
            <Notification variant="error">
              {this.state.notification}
            </Notification>
          );
        }
      } else if (409 === this.state.errorStatus) {
        if ('' === this.state.notification) {
          if ('en' === this.props.lang) {
            return (
              <Notification variant="error">
                Error starting session. Please try again.
              </Notification>
            );
          } else {
            return (
              <Notification variant="error">
                Erreur de session. Veuillez réessayer.
              </Notification>
            );
          }
        } else {
          return (
            <Notification variant="error">
              {this.state.notification}
            </Notification>
          );
        }
      } else if (
        400 <= this.state.errorStatus &&
        500 > this.state.errorStatus
      ) {
        if ('en' === this.props.lang) {
          return (
            <Notification variant="error">
              An unknown error has occurred.
            </Notification>
          );
        } else {
          return (
            <Notification variant="error">
              Une erreur inconnue s'est produite.
            </Notification>
          );
        }
      } else if (500 <= this.state.errorStatus) {
        if ('en' === this.props.lang) {
          return (
            <Notification variant="error">
              An internal server error has occurred.
            </Notification>
          );
        } else {
          return (
            <Notification variant="error">
              Une erreur de serveur interne s'est produite.
            </Notification>
          );
        }
      }
    }
  };

  render() {
    return (
      <form onSubmit={this.validateForm} key={'form'}>
        <Input
          name="email"
          label={this.setEmailLang(this.props.lang)}
          value={this.props.email}
          autoComplete="off"
          autoCapitalize="none"
          autoCorrect="off"
          autoSave="none"
          spellCheck="false"
          id="input#username"
          feedback={this.state.emailStatus}
          error={this.setEmailError()}
          onChange={this.props.setInput}
          onBlur={this.validateEmail}
        />
        <Input
          name="numStudents"
          label={this.setStudentNumber(this.props.lang)}
          value={this.props.numStudents}
          autoComplete="off"
          autoCapitalize="none"
          autoCorrect="off"
          autoSave="none"
          spellCheck="false"
          id="input#number"
          feedback={this.state.numStudentsStatus}
          error={this.setNumberError()}
          onChange={this.props.setInput}
          onBlur={this.validateNumber}
          onLoad={this.validateNumber}
        />
        <div role="radiogroup">
          <Box tag="fieldset">
            <legend id="workshopSelect">
              <Text
                bold
                size="medium"
                children={this.setRadioLabel(this.props.lang)}
              />
            </legend>
            <Radio
              label={this.setFootprintLang(this.props.lang)}
              name="workshop"
              value="Footprint"
              checked={this.props.workshop === 'Footprint'}
              onChange={this.validateRadio}
              feedback={this.state.workshopStatus}
              error={this.setRadioError()}
            />
            <Radio
            label={this.setImpactLang(this.props.lang)}
            name="workshop"
            value="Impact"
            checked={this.props.workshop === 'Impact'}
            onChange={this.validateRadio}
            feedback={this.state.workshopStatus}
            />
            <Radio
              label={this.setInControlLang(this.props.lang)}
              name="workshop"
              value="InControl"
              checked={this.props.workshop === 'InControl'}
              onChange={this.validateRadio}
              feedback={this.state.workshopStatus}
            />
            <Radio
              label={this.setHappinessLang(this.props.lang)}
              name="workshop"
              value="Happiness"
              checked={this.props.workshop === 'Happiness'}
              onChange={this.validateRadio}
              feedback={this.state.workshopStatus}
            />
            <Radio
              label={this.setAILang(this.props.lang)}
              name="workshop"
              value="ResponsibleAI"
              checked={this.props.workshop === 'ResponsibleAI'}
              onChange={this.validateRadio}
              feedback={this.state.workshopStatus}
            />
            <Radio
              label={this.setSupportingLang(this.props.lang)}
              name="workshop"
              value="Supporting"
              checked={this.props.workshop === 'Supporting'}
              onChange={this.validateRadio}
              feedback={this.state.workshopStatus}
            />
            <Radio
              label={this.setEmpoweringLang(this.props.lang)}
              name="workshop"
              value="Empowering"
              checked={this.props.workshop === 'Empowering'}
              onChange={this.validateRadio}
              feedback={this.state.workshopStatus}
            />
          </Box>
        </div>
        <Checkbox
          checked={this.state.subscribe}
          onChange={this.handleCheckBox}
          name="test"
          value="test"
          label={this.setCheckboxLang(this.props.lang)}
        />
        {this.notification()}
        <Button type="submit" id="submitButton">{this.setSubmitLang(this.props.lang)}</Button>
        <Paragraph>
          <Link name="adminLogin" onClick={this.props.setFormName} href="#">
            {this.setAdminLoginLinkLang(this.props.lang)}
          </Link>
        </Paragraph>
      </form>
    );
  }
}

export default Login;

Login.propTypes = {
  email: PropTypes.string.isRequired,
  numStudents: PropTypes.string.isRequired,
  workshop: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  setInput: PropTypes.func.isRequired,
  setFormName: PropTypes.func.isRequired
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Login from './Login';
import AdminLogin from './AdminLogin';

class LoginForms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numStudents: '',
      workshop: '',
      email: ''
    };
  }

  setInput = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  setForm = () => {
    let mandatoryProps = {
      lang: this.props.lang,
      setFormName: this.props.setFormName
    };
    let props = {
      ...mandatoryProps,
      numStudents: this.state.numStudents,
      workshop: this.state.workshop,
      setInput: this.setInput,
      email: this.state.email
    };
    if ('login' === this.props.form) {
      return <Login {...props} />;
    } else if ('adminLogin' === this.props.form) {
      return <AdminLogin {...mandatoryProps} />;
    } else {
      return <Login {...props} />;
    }
  };

  render() {
    return <div className="loginForms">{this.setForm()}</div>;
  }
}

LoginForms.propTypes = {
  setFormName: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  setLang: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired
};

export default LoginForms;
